import React from "react"
import { Link } from "gatsby"
import { Redirect } from "@reach/router"
import { css } from "@emotion/core"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CustomNavTitle from "../../components/CustomNavTitle"

import { theme } from "../../styles/theme"

const Assessment = ({ location }) => {
  if (!location.state) {
    return <Redirect to="/taco/" noThrow />
  }

  const { state = { whichRisk: null } } = location
  const { whichRisk } = state

  if (!whichRisk) {
    return <Redirect to="/taco/" noThrow />
  }

  const assessmentData = {
    "cardiac-risk": {
      title: "Cardiac Risk",
      questions: [
        'Does the patient have a diagnosis of "heart failure" congestive cardiac failure (CCF), severe aortic stenosis, or moderate-severe left ventricular dysfunction?',
        "Is the patient on a regular diuretic?",
        "Does the patient have severe anaemia?",
      ],
    },
    "pulmonary-risk": {
      title: "Pulmonary Risk",
      questions: [
        "Is the patient known to have pulmonary oedema?",
        "Does the patient have any respiratory symptoms of undiagnosed cause?",
      ],
    },
    "fluid-balance": {
      title: "Fluid Balance",
      questions: [
        "Is the fluid balance clinically significantly positive?",
        "Is the patient receiving IV fluids (current or within the last 24 hours)?",
        "Is there any peripheral oedema?",
        "Does the patient have hypoalbuminaemia?",
        "Does the patient have significant renal impairment?",
      ],
    },
  }

  return (
    <Layout>
      <SEO title="TACO Risk Assessment" />

      <CustomNavTitle
        title="TACO Risk Assessment"
        destination="/taco/"
        location={location}
      />

      <article className="mt-gap-2" css={theme.components.row}>
        <header css={styles.header}>
          <img
            src={`/listimage-${whichRisk}.svg`}
            css={styles.image}
            alt={`Illustration of ${assessmentData[whichRisk].title}`}
            width={120}
            height={120}
          />
          <h2>{assessmentData[whichRisk].title}</h2>
        </header>

        <ol css={styles.list}>
          {assessmentData[whichRisk].questions.map((question, index) => {
            return (
              <li key={index}>
                <h3 css={styles.questionNumber}>Question {index + 1}</h3>
                <p>{question}</p>
              </li>
            )
          })}
        </ol>

        <section css={styles.warning}>
          <p css={styles.warningTitle}>
            If <strong>YES</strong> to any of the above, please continue below
            to review management of <strong>TACO risk.</strong>
          </p>
          <Link
            to="/taco/manage-risk"
            css={theme.components.button}
            state={{ whichRisk }}
          >
            Steps to manage TACO risk
          </Link>
        </section>
      </article>
    </Layout>
  )
}

const styles = {
  header: css({
    backgroundColor: theme.colours.lightGrey,
    display: "flex",
    alignItems: "center",
    color: theme.colours.red,
    marginBottom: theme.gapHalf,
    borderRadius: theme.borderRadius,
    padding: theme.gapHalf,
  }),
  image: css({
    marginRight: theme.gap,
    maxWidth: 120,
  }),
  list: css({
    listStyle: "none",
    marginBottom: theme.gap,
  }),
  questionNumber: css({
    color: theme.colours.white,
    backgroundColor: theme.colours.blue,
    padding: theme.gap,
    fontWeight: "bold",
    fontSize: theme.fzH3,
    marginBottom: theme.gapHalf,
    marginTop: theme.gap,
    "&+ p": {
      padding: `0 ${theme.gap} ${theme.gap} ${theme.gap}`,
    },
  }),
  warning: css({
    padding: theme.gapDouble,
    borderRadius: theme.borderRadius,
    backgroundColor: theme.colours.lightRed,
    marginTop: theme.gapDouble,
    marginBottom: theme.gapHalf,
  }),
  warningTitle: css({
    color: theme.colours.black,
    fontWeight: "bold",
    marginBottom: theme.gap,
  }),
}

export default Assessment
